import React from 'react';
import '../styles/App.css';
import Normal_button from './Normal_button';
import {close_button_text} from '../shared/supplementary_buttons_texts';

function Image_full(props) {
  let image_holder_temporary_class;
  let image_temporary_class;
  let description_holder_size;
  (function find_temporary_classes() {
    if (props.screen_orientation === "portrait") {
      if (props.image_orientation === "portrait") {
        image_holder_temporary_class = " f-col-c-c-c ";
        image_temporary_class = " height_85vh width_auto padding_9_global ";
        description_holder_size = " height_auto width_12 padding_9_global ";
      } else {
        image_holder_temporary_class = " f-col-c-c-c ";
        image_temporary_class = " width_12 height_auto padding_9_global ";
        description_holder_size = " height_auto width_12 padding_9_global ";
      }
    } else {
      if (props.image_orientation === "portrait") {
        image_holder_temporary_class = " f-col-c-c-c ";
        image_temporary_class = " height_85vh width_auto padding_9_global ";
        description_holder_size = " height_auto width_12 padding_9_global ";
      } else {
        image_holder_temporary_class = " f-col-c-c-c ";
        image_temporary_class = " width_12 height_auto padding_9_global ";
        description_holder_size = " height_auto width_12 padding_9_global ";
      }
    }
  })();
  function generate_pic_description() {
    let image_description;
    if (props.pic_info["description"] !== undefined) {
      let description_contents = props.pic_info["description"].map((description_para) => {
        return <p
        className={"shown_flex f-col-fs-c-c wrappable " +
        "margin_0 padding_6_global " +
        "transparent_object non-bordered " +
        "noto_sans justified_font no_outline " + 
        " height_auto width_12 transparent_object color_3_font small_font justified_font"} 
        tabIndex="0"
        key = {"description_para_" + props.pic_info["description"].indexOf(description_para)}
        >
          {description_para}
        </p>
      });
      image_description = 
      <div
      className = {"shown_flex non-wrappable f-col-fs-c-c " +
      description_holder_size + " margin_0 " +
      "transparent_object non-bordered"}
      >
        {description_contents}
      </div>
    }
    return image_description;
  }
  return (
    <div
    className="position absolute position_top_left shown_flex 
    f-col-c-c-c non-wrappable
    height_auto width_12 margin_0 padding_0
    transparent_object non-bordered"
    > 
      <Normal_button
      button_flex_class = " f-col-c-c-c " 
      button_size_class = {" height_auto width_auto "}
      button_text_class = " material-icons large_icons_font centered_font "
      button_supplementary_class = " non-bordered position_absolute position_up_right z_index_2"
      hover_type = "from_transparent"
      button_text={close_button_text}
      local_click_action={props.close_image}
      tab_index_ref = "0"
      pop_up = "false"
      />
      <div
      className={"position_relative shown_flex non-wrappable " +
      image_holder_temporary_class + " height_auto width_12 margin_0 padding_0 " +
      "transparent_object opaque z_index_1"}
      >
        <img
        className={"shown_flex f-col-c-c-c non-wrappable " +
        "margin_0 " +
        "image_contain transparent_object no_outline" + image_temporary_class}
        src={props.pic_info["src"]}
        alt={props.pic_info["alt"]}
        tabIndex="0"
        />
        {generate_pic_description()};
      </div>
    </div>
  )
}
export default Image_full;