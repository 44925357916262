import React from 'react';
import '../styles/App.css';
import greeting_page_texts from '../shared/greeting_page_text';
import { render } from '@testing-library/react';

class Home_page extends React.Component {
  constructor(props) {
    super(props);
  }  
  componentDidMount() {
    this.props.return_to_homepage();
  }
  render () {
    let temporary_classes; 
    if (this.props.opened_article === "") {
      if (this.props.layout === "mobile") {
        temporary_classes = " height_10 ";
      } else {
        temporary_classes = " height_9 ";
      }
    } else {
      temporary_classes = " height_12 ";
    }
    /*previous parents of main
    /*}<div
    id = "home_intermediate_holder"
    className={"position_fixed position_top_left shown_flex f-col-c-c-c " +
    "non-wrappable " +
    "color_3_object " + temporary_classes + " width_12 opaque margin_0 padding_0 non-bordered " +
    "z_index_1"}
    >
      <div
      id="layer_2"
      className="position_absolute shown_flex f-col-c-c-c non-wrappable
      transparent_object height_12 width_12 opacity_6 margin_0 padding_0 
      non-bordered 
      z_index_1"
      >
      </div>
    */
   /*initial class list for main
   className={"position_relative shown_flex f-col-fs-c-sb non-wrappable " +
      "transparent_object height_12 width_12 opaque " + 
      "margin_0 padding_0 " +
      "non-bordered z_index_2 overflow_y"}
   */
  return (
    
      <main
      id="home_contents_holder"
      className = "main_home"
      //ref={this.initial_ref}
      //role="region"
      aria-live="polite"
      tabIndex="1"
      role="region"
      aria-label={(greeting_page_texts).toString()}
      >           
        <h1
        className="position_relative shown_flex f-col-c-c-c non-wrappable
        transparent_object height_8 width_12 margin_0 padding_0 non-bordered
        opaque
        montserrat_700 extra_large_font color_1_font centered_font
        z_index_1"
        >
          {greeting_page_texts[0]}
        </h1>
        <h2
        className={"position_relative shown_flex f-col-c-c-c non-wrappable " +
        "transparent_object height_4 width_12 margin_0 padding_3_left_right " +
        "non-bordered opaque " +
        "montserrat_600 large_font color_1_font centered_font " +
        "z_index_1"}
        >
            {greeting_page_texts[1]}
          </h2>
      </main>
  )
  }
}

export default Home_page;