import React from 'react';
import '../styles/App.css';
import Contents_preview from './Contents_preview';
import Article_display from './Article_display';

function Contents (props) {
  
  function check_for_opened_article() {
    if (props.opened_article === "") {
      return (<Contents_preview
      layout={props.layout}
      screen_orientation={props.screen_orientation}
      section_info={props.section_info}
      />);
    } else {
      return (<Article_display
      layout={props.layout}
      screen_orientation={props.screen_orientation}
      section_info = {props.section_info}
      opened_article={props.opened_article}
      no_close="false"
      />);
    }
  }
  function convert_section_reference() {
    const section_reference = 
    ((props.section_info["section_title"]).toLowerCase()).replace(
      / /g, "_"
    );
    return section_reference;
  }
  const section_reference = convert_section_reference();
  return (
    <section 
    id={section_reference + "_holder"}
    className="position_relative shown_flex f-col-sa-c-sb non-wrappable
    color_1_object height_auto width_12 opaque margin_0 padding_0 
    non-bordered 
    z_index_1">
      {check_for_opened_article()}
    </section>
  );
}

export default Contents;