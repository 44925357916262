import React from 'react';
import '../styles/App.css';

function Standard_para(props) {
  return (
    <p
    className={"shown_flex f-col-fs-fs-fs wrappable " +
    "margin_0 " +
    "transparent_object non-bordered " +
    "noto_sans justified_font no_outline " + props.size_color_other_classes} 
    tabIndex={(props.tabbable === "true") ? "0" : ""}
    >
      {props.para_text}
    </p>
  )
}

export default Standard_para;