import React from 'react';
import '../styles/App.css';

class Heading extends React.Component {
  constructor(props) {
    super(props);
    this.native_heading = React.createRef();
  }
  componentDidMount() {
    if (this.props.initial_focus === "true") {
      this.native_heading.current.focus();
    }
  }
  render() {
    return (
      <h3
      className={"shown_flex f-col-fs-c-c wrappable " +
      "height_auto margin_0 " + 
      "transparent_object non-bordered " +
      "centered_font no_outline " + this.props.class_list}
      tabIndex = {(this.props.tabbable === "true") ? "0" : ""}
      ref={this.native_heading}
      >
        {this.props.heading_text}
      </h3>
    );
  }
}
export default Heading;