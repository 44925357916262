import React from 'react';
import '../styles/App.css';
import Image_preview from './Image_preview';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    }
    this.generate_images = this.generate_images.bind(this);
    this.scroll = this.scroll.bind(this);
    this.carousel_open_image = this.carousel_open_image.bind(this);
    this.close_image = this.close_image.bind(this);
  }
  carousel_open_image(button) {
    this.props.local_click_action(button)
  }
  close_image() {
    this.setState ({
      opened_image: ""
    });
  }
  scroll () {
    if (this.state.count === this.props.carousel_info["carousel_contents"].length - 1) {
      this.setState({count: 0});
    } else {
      this.setState({count: this.state.count + 1})
    }
  }
  generate_images () {
    let images = this.props.carousel_info["carousel_contents"];
    let generated_images = [];
    images.map((image, index) => {
      let generated_image = <Image_preview
      opened_article = {this.props.opened_article}
      screen_orientation = {this.props.screen_orientation}
      image_orientation = {image["orientation"]}
      contents_width = {this.props.contents_width}
      pic_info = {image}
      carousel_image = "true"
      key = {index}
      />
      generated_images.push(generated_image);
    })
    return generated_images;
  }
  componentDidMount() {

    setInterval(() => {
      this.scroll()
    }, 6200);
  }
  componentWillUnmount() {
    clearInterval(this.scroll)
  }
  render() {
    const images = this.generate_images();
    return <div
    className={"shown_flex wrappable " +
    "opaque " +
    " extra_thin_border bordered color_3_border no_outline " +
    "height_auto width_12 f-col-fs-c-c color_1_hovered_object carousel_image_holder"}     
    >
      {images[this.state.count]}
    
    </div>
  }
}
export default Carousel;