import React from 'react';
import '../styles/App.css';

function Para_with_links(props) {
  let para_contents = 
  props.contents.map((item) => {
    if (item["type"] === "text") {
      return(
        <p
        className={"shown_inline margin_0 padding_0 transparent_object "+
        "non-bordered noto_sans justified_font no_outline " + props.font_classes}
        key = {"para_with_links_content_" + props.contents.indexOf(item)}
        >
          {item["contents"]}
          <a
          className = {"shown_inline margin_0 padding_0 transparent_object "+
          "non-bordered noto_sans justified_font " + props.font_classes}
          href = {item["link_href"]}
          target = {item["link_target"]}
          title = {item["link_title"]}
          key = {"para_with_links_content_" + props.contents.indexOf(item)}
          >
            {item["link_text"]}
          </a>
        </p>  
      );
    }
  });
  return (
    <div
    className={"shown_flex f-r-fs-c-c wrappable " +
    "margin_0 padding_6_global " +
    "transparent_object non-bordered " +
    " " + props.holder_size_color_classes} 
    tabIndex={(props.tabbable === "true") ? "0" : ""}
    >
      {para_contents}
    </div>
  )
}

export default Para_with_links;