import logo from '../images/logo_gray_medium.png';
import about_page_carousel_image_1 from '../images/Carousels_pics/About_page/about_page_image_1.png';
import about_page_carousel_image_2 from '../images/Carousels_pics/About_page/about_page_image_2.png';
import about_page_carousel_image_3 from '../images/Carousels_pics/About_page/about_page_image_3.png';
import about_page_carousel_image_4 from '../images/Carousels_pics/About_page/about_page_image_4.png';
import about_page_carousel_image_5 from '../images/Carousels_pics/About_page/about_page_image_5.jpg';
import about_page_carousel_image_6 from '../images/Carousels_pics/About_page/about_page_image_6.png';
import project_services_main_carousel_image_1 from '../images/Carousels_pics/Projects_services/project_services_main_image_1.png';
import project_services_main_carousel_image_2 from '../images/Carousels_pics/Projects_services/project_services_main_image_2.png';
import project_services_main_carousel_image_3 from '../images/Carousels_pics/Projects_services/project_services_main_image_3.png';
import project_services_air_2_carousel_image_1 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_1.png';
import project_services_air_2_carousel_image_2 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_2.png';
import project_services_air_2_carousel_image_3 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_3.png';
import project_services_air_2_carousel_image_4 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_4.JPG';
import project_services_air_2_carousel_image_5 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_5.png';
import project_services_air_2_carousel_image_6 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_6.png';
import project_services_air_2_carousel_image_7 from '../images/Carousels_pics/Projects_services/Air_2/project_services_air_2_image_7.png';
import project_services_custom_3_carousel_image_1 from '../images/Carousels_pics/Projects_services/Custom_3/projects_services_custom_services_image_1.png';
import project_services_custom_3_carousel_image_2 from '../images/Carousels_pics/Projects_services/Custom_3/projects_services_custom_services_image_2.png';
import project_services_earth_1_carousel_image_1 from '../images/Carousels_pics/Projects_services/Earth_1/projects_services_earth_1_image_1.png';
import project_services_earth_1_carousel_image_2 from '../images/Carousels_pics/Projects_services/Earth_1/projects_services_earth_1_image_2.png';
import project_services_earth_1_carousel_image_3 from '../images/Carousels_pics/Projects_services/Earth_1/projects_services_earth_1_image_3.png';
import project_services_earth_1_carousel_image_4 from '../images/Carousels_pics/Projects_services/Earth_1/projects_services_earth_1_image_4.png';
import project_services_earth_1_carousel_image_5 from '../images/Carousels_pics/Projects_services/Earth_1/projects_services_earth_1_image_5.png';
import project_services_earth_1_carousel_image_6 from '../images/Carousels_pics/Projects_services/Earth_1/projects_services_earth_1_image_6.png';

const about_page_carousel_images = {about_page_carousel_image_1, about_page_carousel_image_2, about_page_carousel_image_3,
about_page_carousel_image_4, about_page_carousel_image_5, about_page_carousel_image_6};
const project_services_main_carousel_images = {project_services_main_carousel_image_1, 
project_services_main_carousel_image_2, project_services_main_carousel_image_3};
const project_services_air_2_carousel_images = {project_services_air_2_carousel_image_1, project_services_air_2_carousel_image_2,
project_services_air_2_carousel_image_3, project_services_air_2_carousel_image_4, project_services_air_2_carousel_image_5, 
project_services_air_2_carousel_image_6, project_services_air_2_carousel_image_7}
const project_services_custom_3_carousel_images = {project_services_custom_3_carousel_image_1,
project_services_custom_3_carousel_image_2}
const project_services_earth_1_carousel_images = {project_services_earth_1_carousel_image_1,
project_services_earth_1_carousel_image_2, project_services_earth_1_carousel_image_3,
project_services_earth_1_carousel_image_4, project_services_earth_1_carousel_image_5,
project_services_earth_1_carousel_image_6}

const logo_image = {logo}

export {logo_image, about_page_carousel_images,
project_services_main_carousel_images, project_services_air_2_carousel_images,
project_services_custom_3_carousel_images, project_services_earth_1_carousel_images};