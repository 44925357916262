import React from 'react';
import '../styles/App.css';
import {activate_element} from '../general_functions';
import {NavLink} from 'react-router-dom'

class Back_button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: "false",
      selected: "false"
    }
    this.key_affected_item = React.createRef();
    this.convert_button_text = this.convert_button_text.bind(this);
    this.find_temporary_classes = this.find_temporary_classes.bind(this);
    this.hover_on = this.hover_on.bind(this);
    this.hover_off = this.hover_off.bind(this);
    this.local_click_action = this.local_click_action.bind(this);
    this.check_pressed_status = this.check_pressed_status.bind(this);
    this.enter_event = this.enter_event.bind(this);
  }
  convert_button_text() {
    const reference = ((this.props.button_text).toLowerCase()).replace(
      / /g, "_"
    );
    return reference;
  }
  find_temporary_classes() {
    const temporary_classes = activate_element(this) + 
    this.props.button_flex_class + 
    this.props.button_size_class +
    this.props.button_text_class +
    this.props.button_supplementary_class;
    return temporary_classes;
  }
  hover_on() {
    this.setState({
      hovered: "true"
    });
  }
  hover_off() {
    this.setState({
      hovered: "false"
    });
  }
  local_click_action() {
    //this.props.local_click_action(this);
  }
  check_pressed_status() {
    let pressed_status;
    if (this.props.selected !== undefined && 
    this.state.selected === undefined) {
      pressed_status = this.props.selected;
    } else if (this.props.selected === undefined && 
    this.state.selected !== undefined) {
      pressed_status = this.state.selected;
    } else if (this.props.selected !== undefined && 
    this.state.selected !== undefined) {
      pressed_status = this.props.selected;
    } else {
      pressed_status = "false";
    };
    return pressed_status;
  }
  enter_event (additionalButtonEnterEvent) {
    if (additionalButtonEnterEvent.keyCode && 
    additionalButtonEnterEvent.keyCode === 13) {        
      additionalButtonEnterEvent.preventDefault();
      if ((this.key_affected_item.current.id).toString() === 
      (document.activeElement.id).toString()) {
        this.key_affected_item.current.click();
      }
    };
  };
  componentDidMount() {
    window.addEventListener("keydown", this.enter_event);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.enter_event)
  }
  render() {
    const reference = this.convert_button_text();
    const temporary_classes = this.find_temporary_classes();
    return (
      <NavLink
      to = {"/" + reference}
      id={reference + "_button"}
      className={"shown_flex wrappable " +
      "opaque margin_0 " +
      "padding_9_global bordered transparent_border no_outline " +
      temporary_classes}
      hover_type = {this.props.hover_type}
      ref = {this.key_affected_item}
      role="button"
      aria-pressed={this.check_pressed_status()}
      aria-haspopup = {(this.props.pop_up === "true") ? "true" : ""}
      aria-expanded = {(this.props.pop_up === "true") ? 
      this.props.selected : ""}
      tabIndex={this.props.tab_index_ref}
      onMouseEnter = {this.hover_on}
      onFocus = {this.hover_on}
      onBlur={this.hover_off}
      onMouseLeave = {this.hover_off}
      onClick={() => this.local_click_action(this)}
      >
        Back
      </NavLink>  
    );
  }
}
export default Back_button;