function activate_element(binder) {
  let temporary_classes;
  let hovered_state;
  let selected_state;
  let hover_type = binder.props.hover_type;
  if (binder.props.hovered !== undefined) {
    if (binder.props.hovered === "true") {
      hovered_state = "true";
    } else if (binder.props.hovered === "false") {
        hovered_state = "false";
    }
  } else if (binder.state.hovered !== undefined) {
    if (binder.state.hovered === "true") {
      hovered_state = "true";
    } else if (binder.state.hovered === "false") {
      hovered_state = "false";
    }
  } else if (binder.state.hovered === undefined && 
    binder.props.hovered === undefined) {
    hovered_state = "false";
  }
  if (binder.props.selected !== undefined) {
    if (binder.props.selected === "true") {
      selected_state = "true";
    } else if (binder.props.selected === "false") {
      selected_state = "false";
    }
  } else if (binder.state.selected !== undefined) {
    if (binder.state.selected === "true") {
      selected_state = "true";
    } else if (binder.state.selected === "false") {
      selected_state = "false";
    }
  } else if (binder.state.selected === undefined &&
  binder.props.selected === undefined) {
    selected_state = "false";
  } else if (binder.state.selected !== undefined &&
    binder.props.selected !== undefined) {
    selected_state = binder.props.selected;
  }
  if (hovered_state === "false" && selected_state === "false") {
    switch (hover_type) {
      case "from_color_1":
        temporary_classes = " color_1_object color_3_font ";
      break;
      case "from_transparent":
        temporary_classes = " transparent_object color_3_font ";
      break;
      default: 
      temporary_classes = " color_1_object color_3_font ";
    }
  } else if (hovered_state === "true" && 
  selected_state === "false") {
    temporary_classes = " color_1_hovered_object color_3_font "
  } else if ((hovered_state === "false") && 
  selected_state === "true") {
    temporary_classes = " color_3_object color_1_font "
  } else if (hovered_state === "true" && 
  selected_state === "true") {
    temporary_classes = " color_3_hovered_object color_1_font "
  }
  return temporary_classes;
}
function determine_layout() {
  let screen_info = [];
  let layout;
  let screen_orientation;
  function check_layout() {
    if (window.matchMedia("(min-width: 601px) and (max-width: 1499px)").matches) {
      layout = "medium";
    } else if (window.matchMedia("(min-width: 1500px)").matches) {
      layout = "large";
    } else {
      layout = "mobile";
    }
  };
  function check_orientation() {
    if (window.matchMedia("(orientation: landscape)").matches) {
      screen_orientation = "landscape";
    } else {
      screen_orientation = "portrait";
    }
  }
  check_layout();
  check_orientation();
  screen_info.push(layout);
  screen_info.push(screen_orientation);
  return screen_info;
}
function update_layout_indicator(binder) {
  function update() {
    const current_layout = binder.state.layout;
    const current_screen_orientation = binder.state.screen_orientation;
    const updated_screen_info = determine_layout();
    const new_layout = updated_screen_info[0];
    const new_screen_orientation = updated_screen_info[1];
    if (new_layout !== current_layout) {
      binder.setState({
        layout: new_layout
      });
    }
    if (new_screen_orientation !== current_screen_orientation) {
      binder.setState({
        screen_orientation: new_screen_orientation
      });
    }
  }    
  window.addEventListener("resize", update);
}
export {activate_element, determine_layout, update_layout_indicator};