import React from 'react';
import '../styles/App.css';
import Article_main_contents from './Article_main_contents';
import Image_full from './Image_full';

class Article_display extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened_image: ""
    }
    this.determine_contents = this.determine_contents.bind(this);
    this.open_image = this.open_image.bind(this);
    this.close_image = this.close_image.bind(this);
  }
  open_image(button) {
    this.setState({
      opened_image: button.props.pic_info
    });
  }
  close_image() {
    this.setState ({
      opened_image: ""
    });
  }
  determine_contents() {
    let contents;
    if (this.state.opened_image === "") {
      contents = <Article_main_contents
      section_info = {this.props.section_info}
      opened_article = {this.props.opened_article}
      layout = {this.props.layout}
      screen_orientation = {this.props.screen_orientation}
      close_sub_section = {this.props.close_sub_section}
      open_image={this.open_image}
      no_close={this.props.no_close}
      />
    } else {
      contents = <Image_full
      layout={this.props.layout}
      screen_orientation = {this.props.screen_orientation}
      image_orientation = {this.state.opened_image["orientation"]}
      pic_info = {this.state.opened_image}
      close_image={this.close_image}
      />
    }
    return contents;
  }
  render() {
    let contents = this.determine_contents();
    return contents;
  }
}
export default Article_display;