import React from 'react';
import '../styles/App.css';
import Main_contents_holder from './Main_contents_holder';
import Home_page from './Home_page';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

function Layer_2 (props) {
  
    let routes = [];
    let location = useLocation();
    const contents = props.contents;
    const i = contents.length;
    for (let j = 0; j < i; j++) {
      let link = "/" + ((contents[j].section_title).toLowerCase()).replace(/ /g, "_").replace(/,/g,"").replace(/:/g,"").replace(/;/g, "");
      let component;
      let selected_section;
      let route_summary = {};
      if (contents[j].section_contents.length <= 1) {
        selected_section = contents[j];
        component = <Main_contents_holder
        section_info={contents[j]}
        layout={props.layout}
        screen_orientation={props.screen_orientation}
        opened_article={contents[j].section_contents[0]}
        section_update = {props.section_update}
        //open_sub_section={this.props.open_sub_section}
        //close_sub_section={this.props.close_sub_section}
        />
      } else {
        selected_section = contents[j];
        component = <Main_contents_holder
        section_info={contents[j]}
        layout={props.layout}
        screen_orientation={props.screen_orientation}
        opened_article=""
        section_update = {props.section_update}
        />
        const k = contents[j].section_contents.length;
        for (let l = 0; l < k; l++) {
          let sub_link;
          let sub_contents;
          let sub_component;
          let sub_route = {};
          sub_link = link + "/" + ((contents[j].section_contents[l].article_name).toLowerCase()).replace(/ /g, "_").replace(/,/g,"").replace(/:/g,"").replace(/;/g, "");
          sub_contents = contents[j].section_contents[l];
          sub_component = <Main_contents_holder
          section_info={contents[j]}
          layout={props.layout}
          screen_orientation={props.screen_orientation}
          opened_article={contents[j].section_contents[l]}
          section_update = {props.section_update}
          //open_sub_section={this.props.open_sub_section}
          //close_sub_section={this.props.close_sub_section}
          />
          sub_route.route = sub_link;
          sub_route.contents = sub_contents;
          sub_route.component = sub_component;
          routes.push(sub_route);
        };
      }
      route_summary.route = link;
      route_summary.section = selected_section;
      route_summary.component = component;
      routes.push(route_summary);
    } 
  const routes_expressed = routes.map((route, i) => {
    return (
      <Route 
      exact path = {route.route}
      key = {i}
      render={() => route.component}
      >
      </Route>
    )
  });       
  return (
      <Switch>
        <Route
        exact path = "/"
        >
          <Redirect
            to="/home"
          />
        </Route>
        <Route exact path = "/home">
          <Home_page
          return_to_homepage = {props.return_to_homepage}
          />
        </Route>
        {routes_expressed}
        <Route path="*">
        <div>
          <h3>
            No match for <code>{location.pathname}</code>
          </h3>
        </div>
        </Route>
      </Switch>
  );
}

export default Layer_2;