import {about_page_carousel_images,
  project_services_main_carousel_images, project_services_air_2_carousel_images,
  project_services_custom_3_carousel_images, project_services_earth_1_carousel_images} from '../components/images';

const contents = [
  {
    lang_name: "en",
    lang_contents: [
      {
        /*section_title will appear on the website as a button allowing to
        display relevant section and as a heading of the section in mobile layout*/
        section_title: "About us",
        /*section contents is an array of objects. each object in an array 
        represents an article */
        section_contents: [
          {
            article_name: "",
            /*article contents is an array of objects. each object is a paragraph
            ('type: "para"' or an image ('type: "image"'))*/
            article_contents: [
              {
                type: "carousel",
                carousel_contents: [
                  {
                    orientation: "landscape",
                    src: about_page_carousel_images.about_page_carousel_image_1,
                    alt: "ASTER-TIR color composite image. Silica has lower emissivity in ASTER band 12, "+
                    "carbonate minerals  represented by calcite and dolomite have high emissivity in ASTER "+
                    "band 10 to 13 spectral region. Red corresponds to band 13, green to band 12.",
                    description: ["ASTER-TIR color composite image. Silica has lower emissivity in ASTER band 12,"+
                    " carbonate minerals  represented by calcite and dolomite have high emissivity in ASTER band "+
                    "10 to 13 spectral region. Red corresponds to band 13, green to band 12"]
                  },
                  {
                    orientation: "landscape",
                    src: about_page_carousel_images.about_page_carousel_image_5,
                    alt: "AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies.",
                    description: ["AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies."]
                  },
                  {
                    orientation: "landscape",
                    src: about_page_carousel_images.about_page_carousel_image_2,
                    alt: "ASTER-TIR color composite image. Silica has lower emissivity in ASTER band 12, "+
                    "carbonate minerals  represented by calcite and dolomite have high emissivity in "+
                    "ASTER band 10 to 13 spectral region. Red corresponds to band 13, green to band 12.",
                    description: ["ASTER-TIR color composite image. Silica has lower emissivity in ASTER band 12, "+
                    "carbonate minerals  represented by calcite and dolomite have high emissivity in ASTER "+
                    "band 10 to 13 spectral region. Red corresponds to band 13, green to band 12."]
                  },
                  {
                    orientation: "landscape",
                    src: about_page_carousel_images.about_page_carousel_image_4,
                    alt: "Landsat 7 ETM. 4/3/2 (RGB) false color composites with vegetation seen in red, "+
                    "iron rich soils and outer areas of the alteration zones in green.",
                    description: ["Landsat 7 ETM. 4/3/2 (RGB) false color composites with vegetation seen in red, "+
                    "iron rich soils and outer areas of the alteration zones in greens."]
                  },
                  {
                    orientation: "landscape",
                    src: about_page_carousel_images.about_page_carousel_image_3,
                    alt: "Summer Heat Wave occurrences over the Balkans in 1999 based on the historical data.",
                    description: ["Summer Heat Wave occurrences over the Balkans in 1999 based on the historical data."]
                  },
                  {
                    orientation: "landscape",
                    src: about_page_carousel_images.about_page_carousel_image_6,
                    alt: "Estimated Tornado Risk Index for the State of Oklahoma.",
                    description: ["Estimated Tornado Risk Index for the State of Oklahoma."]
                  }
                ]
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Athena7 Labs was established by a group of scientists "+
                "from leading European Universities joined by experienced software "+
                "developers and R&D specialists."
              },          
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Currently our research is focused on development of "+
                "natural hazards prediction models, earth observation, predictive risk "+
                "modeling and software development."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Athena7 is a privately owned company that also delivers custom "+
                "scientific solutions. We perform data and satellite data analysis, provide AI, "+
                "modeling and software solutions to our customers."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: ""
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: ""
              },
              {
                type: "para",
                sub_type: "standard_para_without_intends",
                contents: "Open Position: Software Engineer"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: ""
              },
              {
                type: "para",
                sub_type: "standard_para_without_intends",
                contents: "Required skills:"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Master’s degree or higher in Computer Science, Physics, Mathematics and similar"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "High proficiency in Python, with emphasis on data analysis"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Experience in designing and implementing data models and APIs"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Proficiency with git and CI/CD workflows"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Strong familiarity with Linux architecture and networking concepts"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Strong communication and problem-solving skills"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: ""
              },
              {
                type: "para",
                sub_type: "standard_para_without_intends",
                contents: "Desired skills:"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Familiarity with web development technologies (HTML / JavaScript / CSS) is a plus"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Some basic knowledge / familiarity with C/C++ and Octave is a plus"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Understanding of HPC and parallel computing concepts"
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Experience in geoscience or geospatial applications"
              },
              {
                type: "para_with_links",
                contents: "Contact us: info(at)iathena7.com",
                details: [
                  {
                    type: "text",
                    contents: "Contact us: ",
                    link_text: " info(at)iathena7.com",
                    link_href: "mailto:info@iathena7.com",
                    link_target: "_self",
                    link_title: "send us an e-mail"
                  },
                ]
              },
            ]
          }
        ]
      },
      {
        section_title: "Projects and Services",
        section_contents: [
          {
            article_name: "Remote Sensing: Earth Observation",
            article_contents: [
              {
                type: "para",
                visibility: "hidden",
                contents: "Multi-band satellite data analysis for natural resources exploration, ecological monitoring and agriculture."
              },
              {
                type: "image",
                visibility: "hidden",
                orientation: "landscape",
                src: project_services_earth_1_carousel_images.project_services_earth_1_carousel_image_6,
                alt: "Landsat 7 ETM. 4/3/2 (RGB) false color composites with vegetation seen in red, "+
                "iron rich soils and outer areas of the alteration zones in green.",
                description: ["Landsat 7 ETM. 4/3/2 (RGB) false color composites with vegetation seen in red, "+
                "iron rich soils and outer areas of the alteration zones in greens."]
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: ""
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Forest fires mapping"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Dust storms, tracking eruptive volcanoes"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Remote Sensing of precipitation and clouds"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Monitoring of cities' and urban areas' growth"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Monitoring of farmlands and forest areas"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Landscape and ice cover changes"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Precious metals and mineral exploration"
              },
              {
                type: "carousel",
                carousel_contents: [
                  {
                    orientation: "landscape",
                    src: project_services_earth_1_carousel_images.project_services_earth_1_carousel_image_2,
                    alt: "ASTER-TIR color composite image. Silica has lower emissivity in ASTER band "+
                    "12, carbonate minerals represented by calcite and dolomite have high emissivity "+
                    "in ASTER band 10 to 13 spectral region. Red corresponds to band 13, green to band 12.",
                    description: ["ASTER-TIR color composite image. Silica has lower emissivity in "+
                    "ASTER band 12, carbonate minerals represented by calcite and dolomite "+
                    "have high emissivity in ASTER band 10 to 13 spectral region. Red corresponds to "+
                    "band 13, green to band 12."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_earth_1_carousel_images.project_services_earth_1_carousel_image_4,
                    alt: "ASTER-TIR color composite image. Silica has lower emissivity in ASTER "+
                    "band 12, carbonate minerals represented by calcite and dolomite have high emissivity "+
                    "in ASTER band 10 to 13 spectral region. Red corresponds to band 13, green to band 12.",
                    description: ["ASTER-TIR color composite image. Silica has lower emissivity in "+
                    "ASTER band 12, carbonate minerals represented by calcite and dolomite have high "+
                    "emissivity in ASTER band 10 to 13 spectral region. Red corresponds to band "+
                    "13, green to band 12."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_earth_1_carousel_images.project_services_earth_1_carousel_image_3,
                    alt: "ASTER-TIR color composite image. Green corresponds to band 12, red to band 13. "+
                    "Silica has lower emissivity in ASTER band 12 and band 10, where carbonates have higher emissivity.",
                    description: ["ASTER-TIR color composite image. Green corresponds to band 12, red to band 13. "+
                    "Silica has lower emissivity in ASTER band 12 and band 10, where carbonates have higher emissivity."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_earth_1_carousel_images.project_services_earth_1_carousel_image_1,
                    alt: "Abrams ratios. A product of a logical operation on the bands for each of "+
                    "R/G/B channels as follows – 3/1:5/4:5/7.",
                    description: ["Abrams ratios. A product of a logical operation on the bands for each of "+
                    "R/G/B channels as follows – 3/1:5/4:5/7."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_earth_1_carousel_images.project_services_earth_1_carousel_image_6,
                    alt: "Landsat 7 ETM. 4/3/2 (RGB) false color composites with vegetation seen in red, "+
                    "iron rich soils and outer areas of the alteration zones in green.",
                    description: ["Landsat 7 ETM. 4/3/2 (RGB) false color composites with vegetation seen in red, "+
                    "iron rich soils and outer areas of the alteration zones in green."]
                  }
                ]
              },
              {
                type: "para",
                sub_type: "para_sub_heading",
                contents: "ASTER, Landsat, Sentinel satellite imagery for earth mineral, "+
                "basic and precious metals exploration. Common principles of the analysis we apply."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Base and precious metals cannot be detected by remote sensing but "+
                "hydrothermal alteration minerals associated with some ore "+
                "deposits can. Certain diagnostic group of hydrothermal minerals, such as clays, "+
                "phyllosilicates, sulfates and carbonates, can be identified based on their "+
                "spectral signatures, with diagnostic features mainly in the shortwave infrared "+
                "(SWIR) portion of the electromagnetic spectrum. Iron oxides and hydroxides "+
                "normally have lower reflectance in the visible region and higher reflectance "+
                "in the near infrared wavelengths, making the visible and near infrared (VNIR) "+
                "portion of the spectrum critical for the study of these minerals."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Although Landsat TM/ETM+ and Landsat OLI/TIRS have the capability "+
                "of detecting ferric oxides and hydroxides, OH-bearing minerals and "+
                "carbonate-rich alteration zones, they cannot identify specific minerals. "+
                "Bands 1–4 (Landsat ETM+) and 1–5 (Landsat OLI) record energy from the "+
                "VNIR portion of the electromagnetic spectrum where hematite, goethite and "+
                "jarosite’s diagnostic features occur. Bands 5 and 7 (Landsat ETM+) and 9, 6, 7 "+
                "(Landsat OLI) have the capability of recording information from the SWIR "+
                "range where minerals like alunite, kaolinite, dickite, pyrophyllite, zunyite, "+
                "montmorillonite, illite, muscovite, chlorite, epidote and calcite exhibit their "+
                "diagnostic features. Bands 6 (Landsat ETM+) and 10–11 (Landsat TIRS) cover "+
                "the thermal infrared (TIR) portion of the electromagnetic spectrum. "+
                "ASTER has three times more SWIR bands and five times more TIR bands than "+
                "Landsat-7 (ETM+), enhancing the capability for specific mineral detection "+
                "and lithologic characterization."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "As a general rule, Fe-oxides are mainly detected by bands 1 "+
                "and 3. Al–OH-bearing minerals such as clay minerals, alunite, muscovite "+
                "and illite exhibit absorption features in bands 5 and 6. Fe–OH "+
                "mineral species such as jarosite and/or Fe-muscovite are detected by "+
                "band 7, whereas Mg–OH and CO 3 mineral species such as chlorite, epidote "+
                "and/or carbonates are detected by band 8. In terms of alteration zones, "+
                "a phyllic zone rich in illite – muscovite yields a strong Al–OH absorption "+
                "coinciding with ASTER band 6. An argillic zone characterized by kaoliniteand "+
                "alunite collectively exhibits a secondary Al–OH absorption feature "+
                "corresponding with ASTER band 5. A propylitic zone with epidote, chlorite "+
                "and calcite displays absorption features coinciding with ASTER band 8."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Silica minerals represented by the commonest mineral on earth, "+
                "quartz, have a unique spectral property in TIR. They have lower emissivity "+
                "in ASTER band 10 and band 12 spectral regions than in band 11. All the common "+
                "carbonate minerals on earth represented by calcite and dolomite have another "+
                "unique spectral feature. They have low emissivity in ASTER band 14 spectral "+
                "region, and high emissivity in ASTER band 10 to 13 spectral region. "+
                "In silicate rocks, the wavelength at trough in TIR emissivity spectra "+
                "moves to longer wavelength as the rock type changes from felsic to ultramafic."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Indices as products of band ratios are also used for lithologic "+
                "mapping using multispectral remote sensing data. These are the Quartz Index (QI), "+
                "the Carbonate Index (CI) and the bulk content Index (SI): QI = "+
                "(band 11/band 10)/(band 11/band 12). CI = band 13/band 14, SI = band 12/band 13. "+
                "Various band ratios and color composit images (for instance, Sabin) "+
                "are used to enhance alteration zones and are proven to be effective for "+
                "lithological and alteration zones mapping."
              },
              {
                type: "para",
                contents: ""
              },
              {
                type: "para",
                contents: ""
              },
              {
                type: "para",
                contents: ""
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "We deliver custom multi-band analysis of the satellite data "+
                "(including contextualization) to our customers."
              },
              {
                type: "para_with_links",
                contents: "Contact us: info(at)iathena7.com",
                details: [
                  {
                    type: "text",
                    contents: "Contact us: ",
                    link_text: " info(at)iathena7.com",
                    link_href: "mailto:info@iathena7.com",
                    link_target: "_self",
                    link_title: "send us an e-mail"
                  },
                ]
              },
            ]
          },
          {
            article_name: "Extreme Weather Events",
            article_contents: [
              {
                type: "para",
                visibility: "hidden",
                contents: "Atmospheric modeling and data analysis. Remote Sensing. Natural Hazard Risk Index estimation. The Software."
              },
              {
                type: "image",
                visibility: "hidden",
                orientation: "landscape",
                src: project_services_air_2_carousel_images.project_services_air_2_carousel_image_4,
                alt: "AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies.",
                description: ["AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies."]
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: ""
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Analysis of historical data and reanalysis datasets"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Satellite Data processing and analysis"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Multi-scale Remote Sensing of precipitation and clouds as main sources of uncertainty in numerical prediction models"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Atmospheric and GCM Modeling"
              },
              {
                type: "para",
                sub_type: "list_sub_heading",
                contents: "Natural Hazard Risk Index estimation: The method and the software"
              },
              {
                type: "carousel",
                carousel_contents: [
                  {
                    orientation: "landscape",
                    src: project_services_air_2_carousel_images.project_services_air_2_carousel_image_4,
                    alt: "AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies.",
                    description: ["AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_air_2_carousel_images.project_services_air_2_carousel_image_6,
                    alt: "AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies.",
                    description: ["AURA Satellite Aaerosole optical depth (380-480 nm) averages. Tianhe (Skyriver) project impact studies."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_air_2_carousel_images.project_services_air_2_carousel_image_1,
                    alt: "Summer Heat Wave occurences over the Balkans in 1982 based on the historical data.",
                    description: ["Summer Heat Wave occurences over the Balkans in 1982 based on the historical data."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_air_2_carousel_images.project_services_air_2_carousel_image_2,
                    alt: "Summer Heat Wave occurences over the Balkans in 1988 based on the historical data.",
                    description: ["Summer Heat Wave occurences over the Balkans in 1988 based on the historical data."]
                  },
                  {
                    orientation: "landscape",
                    src: project_services_air_2_carousel_images.project_services_air_2_carousel_image_3,
                    alt: "Summer Heat Wave occurences over the Balkans in 1999 based on the historical data.",
                    description: ["Summer Heat Wave occurences over the Balkans in 1999 based on the historical data."]
                  },
                ]
              },
              {
                type: "para",
                sub_type: "para_sub_heading",
                contents: "Natural Hazard Risk Index Estimation: The method and the software"
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Risk indexing is a powerful tool that can provide valuable information about "+
                "risks associated with natural hazards. Such methods were widely used during the last few "+
                "decades, particularly in development, oil and gas, agriculture, nuclear power and air "+
                "transportation industries. Nevertheless, greenhouse-gas induced climate change sets "+
                "demands for more up-to-date methodologies since frequencies and magnitudes of "+
                "extreme weather events depend on the rising global temperature."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "Following hybrid, statistical-modeling approach where we use existing "+
                "historical extreme weather events data, atmospheric modeling, forecast reanalysis "+
                "data and remote sensing imagery we present the method and the software product for "+
                "calculation of the Natural Hazard Risk Index for extreme weather events — "+
                "Tornadoes, Storms, Droughts, Floods and Wildfires within present and a few future climate scenarios. " +
                "Socio-economic factors taken into account in the model are based on the statistics for a certain region."
              },
              {
                type: "para",
                sub_type: "standard_para_with_intends",
                contents: "We also deliver custom natural hazard risk assessment for our customers."
              },
              {
                type: "para_with_links",
                contents: "Contact us: info(at)iathena7.com",
                details: [
                  {
                    type: "text",
                    contents: "Contact us: ",
                    link_text: " info(at)iathena7.com",
                    link_href: "mailto:info@iathena7.com",
                    link_target: "_self",
                    link_title: "send us an e-mail"
                  },
                ]
              },
            ]
          },
          {
            article_name: "Services",
            article_contents: [
              {
                type: "para",
                visibility: "hidden",
                contents: "Custom data processing, software engineering, modeling, and AI solutions."
              },
              {
                type: "para",
                sub_type: "sub_heading",
                contents: ""
              },
              {
                type: "image",
                orientation: "landscape",
                visibility: "hidden",
                src: project_services_custom_3_carousel_images.project_services_custom_3_carousel_image_1,
                alt: "Custom data processing, software engineering, modeling, and AI solutions.",
                description: ["Custom data processing, software engineering, modeling, and AI solutions."]
              },
              {
                type: "para",
                sub_type: "sub_heading_followed_by_para",
                contents: "Natural Language Processing."
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Members of Athena7 team have experience of development "+
                "and training of natural language processing models since 1997, "+
                "including full-cycle development of the software for machine translation."
              },
              {
                type: "para",
                sub_type: "sub_heading_followed_by_para",
                contents: "Chemical reactions modeling. Predictive materials modeling."
              },
              {
                type: "para",
                sub_type: "list_level_one",
                contents: "Our researchers have solid experience in development of custom "+
                "physical and chemical models, Quantum and Classical Molecular "+
                "Dynamics simulations, stochastic modeling."
              },
              {
                type: "para",
                sub_type: "sub_heading",
                contents: "Environmental biochemistry."
              },
              {
                type: "para",
                sub_type: "sub_heading",
                contents: "Image processing and recognition. Telescope and satellite data processing and analysis."
              },
              {
                type: "para",
                sub_type: "sub_heading",
                contents: "Custom dataset analysis and ML solutions."
              },
              {
                type: "para",
                sub_type: "sub_heading",
                contents: "HPC architecture and simulations."
              },
              {
                type: "para",
                sub_type: "sub_heading",
                contents: "Scientific software engineering."
              },
              {
                type: "para_with_links",
                contents: "Contact us: info(at)iathena7.com",
                details: [
                  {
                    type: "text",
                    contents: "Contact us: ",
                    link_text: " info(at)iathena7.com",
                    link_href: "mailto:info@iathena7.com",
                    link_target: "_self",
                    link_title: "send us an e-mail"
                  },
                ]
              }       
            ]
          }
        ],
      },
    ],
  },
]
export default contents;