import React from 'react';
import '../styles/App.css';
import Contents from './Contents';

class Main_contents_holder extends React.Component {
  constructor(props) {
    super(props);
    this.local_section_update = this.local_section_update.bind(this);
  }
  local_section_update() {
    this.props.section_update(this);
  }
  componentDidMount() {
    this.local_section_update();
  }
  render() {
    return(      
      
        <main
        id="main_contents_holder"
        role="region"
        aria-live="polite"
        >
          <Contents
          section_info={this.props.section_info}
          layout={this.props.layout}
          screen_orientation={this.props.screen_orientation}
          opened_article={this.props.opened_article}
          />
        </main>
    );
  }
}

export default Main_contents_holder;