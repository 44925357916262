import React from 'react';
import './styles/App.css';
import {determine_layout, update_layout_indicator} from './general_functions';
import contents from './shared/section_references';
import Navigation_panel from './components/Navigation_panel';
import Layer_2 from './components/Layer_2';
import {logo_image} from './components/images';
import {
  HashRouter as Router,
} from 'react-router-dom';

function App() {
  class Layer_1 extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        layout: determine_layout()[0],
        screen_orientation: determine_layout()[1],
        contents: contents[0].lang_contents,
        selected_button: "Home",
        opened_article: ""
      };
      this.initial_ref = React.createRef();
      this.section_update = this.section_update.bind(this);
      this.open_sub_section = this.open_sub_section.bind(this);
      this.close_sub_section = this.close_sub_section.bind(this);
      this.update_layout = this.update_layout.bind(this);
      this.return_to_homepage = this.return_to_homepage.bind(this);
    }
    section_update(section) {
      this.setState({
        selected_button: section.props.section_info.section_title
      });
    }
    open_sub_section(button) {
      this.setState({
        opened_article: button.props.article_name
      });
    }
    close_sub_section() {
      this.setState({
        opened_article: ""
      });
    }
    
    update_layout() {
      update_layout_indicator(this);
    }
    return_to_homepage() {
      this.setState({
        selected_button: "Home"
      })
    }
    componentDidMount() {
      this.update_layout();
    }
    render () {
      return (<div
      id="layer_1"
      className="root_element"
      >
        <Router>      
          <div
          id="layer_3"
          className="">
            <header
            id="main_header"
            className=""       
            >
              <img
              id="logo"
              className={"shown_flex f-col-c-c-c non-wrappable " +
              "height_10 width_auto opaque margin_0 transparent_object" + 
              "padding_3_left_right non-bordered " +
              "image_contain no_outline"}
              src={logo_image.logo}
              alt={"iAthina 7 logo - letter i in front of letter A surrounded " + 
              "by a circle"}
              >
              </img>
              <Navigation_panel
              layout={this.state.layout}
              main_nav_buttons={this.state.contents}
              selected_button={this.state.selected_button}
              //main_nav_buttons_click={this.main_nav_buttons_click}
              return_to_homepage = {this.return_to_homepage}>
              </Navigation_panel>
            </header>
            <Layer_2
            screen_orientation = {this.state.screen_orientation}
            layout = {this.state.layout}
            contents = {this.state.contents}
            section_update = {this.section_update}
            return_to_homepage = {this.return_to_homepage}
            />
            <footer
            id="footer"        
            >
              <h3
              className="shown_flex f-col-c-c-c non-wrappable
              transparent_object height_12 width_fit margin_0 padding_3_left_right 
              non-bordered opaque
              noto_sans small_font color_3_font justified_font"
              >
                © Athena7 Labs. All rights reserved
              </h3>
            </footer>
          </div>
        </Router>
          
      </div>)
    }
  };
  return (
    <div 
    className="root_element" 
    id="app_root">
      <Layer_1/>
    </div>
  );
}

export default App;
