import React from 'react';
import '../styles/App.css';
import Standard_para from './Standard_para';

function Image_preview(props) {
  const animation_styles = find_animation_styles();
  function find_image_temporary_classes() {
    let image_preview_temporary_classes;
    if (props.opened_article === "") {
      if (props.image_orientation === "portrait") {
        image_preview_temporary_classes = " image_portrait ";
      } else {
        image_preview_temporary_classes = " width_12 height_auto ";
      }  
    } else {
      if (props.image_orientation === "portrait") {
        image_preview_temporary_classes = " width_6 ";
      } else {
        if (props.carousel_image === "true") {
          image_preview_temporary_classes = " width_12 carousel_image ";
        } else {
          image_preview_temporary_classes = " width_12 ";
        }
      }
    }
    return image_preview_temporary_classes;
  }
  function find_holder_temporary_classes() {
  let image_holder_temporary_classes;
    if (props.opened_article === "") {
      image_holder_temporary_classes = " width_12 f-r-fs-c-c";
    } else {
      if (props.image_orientation === "portrait") {
        image_holder_temporary_classes = " f-col-fs-c-c " + props.contents_width;
      } else {
        if (props.carousel_image === "true") {
          image_holder_temporary_classes = " f-col-fs-c-c height_12" + 
          props.contents_width;
        } else {
          image_holder_temporary_classes = " f-col-fs-c-c " + 
          props.contents_width;
        }
      }
    }
    return image_holder_temporary_classes;
  }
  function generate_pic_description () {
    let image_description;
    if (props.opened_article !== undefined &&
    props.opened_article !== "") {
      image_description = props.pic_info["description"].map((description_para) => {
        return <Standard_para
        size_color_other_classes={"height_auto width_auto "+ 
        "transparent_object color_3_font extra_small_font"}
        para_text={description_para}
        key = {"description_para_" + props.pic_info["description"].indexOf(description_para)}
        />
      });
    }
    return image_description;
  }
  function find_animation_styles() {
    let animation_styles;
    if (props.carousel_image === "true") {
      animation_styles = {
        animationName: "carousel", 
        animationDuration: "6s",
        animationFillMode: "both"
      }
    } else {
      animation_styles = {};
    }
    return animation_styles;
  }
  return (<div 
  className={"shown_flex " + find_holder_temporary_classes() +
  " non-wrappable " +
  " margin_0 padding_6_global transparent_object "}
  style = {animation_styles}
  >
    <img
    className={"shown_flex f-col-c-c-c non-wrappable " +
    "width_6 margin_0 padding_6_global " +
    "image_contain transparent_object " + find_image_temporary_classes()}
    src={props.pic_info["src"]}
    alt={props.pic_info["alt"]}
    />
    {generate_pic_description()}
  </div>);
}
export default Image_preview;