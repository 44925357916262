import React from 'react';
import '../styles/App.css';
import Link_button from './Link_button';
import Heading from './Heading';
import Standard_para from './Standard_para';
import Image_preview from './Image_preview';

function Contents_preview (props) {
    
  function click_on(binder) {
    
  }
  function local_open_sub_section(binder) {
    
  }
  function generate_section_heading() {
    let section_heading;
    if (props.layout === "mobile") {
      section_heading = <Heading
      heading_text={props.section_info["section_title"]}
      class_list="width_auto padding_9_global 
      montserrat_600 color_3_font large_font"
      />
    }
    return section_heading;
  }
  function generate_preview_buttons() {
    let preview_contents_list = [];
    let preview_buttons;
    let width_class;
    let image;
    const i = props.section_info["section_contents"].length;
    function fill_preview_contents_array() {
      function find_first_para(item) {
        return item["type"].includes("para");
      };
      function find_first_image(item) {
        return item["type"] === "image";
      };
      for (let j = 0; j < i; j++) {
        let reference_item = [];
        reference_item.push(
          props.section_info["section_contents"][j]["article_name"]
        );
        const first_image = 
        props.section_info["section_contents"][j]["article_contents"].find(
          find_first_image
        );
        if (first_image !== undefined) {
          reference_item.push(first_image);
        }
        const first_para = 
        props.section_info["section_contents"][j]["article_contents"].find(
          find_first_para
        );
        reference_item.push(first_para);
        preview_contents_list.push(reference_item);
      }
    }
    fill_preview_contents_array();
    if (props.layout === "mobile") {
      width_class = " width_12 ";
    } else {
      width_class = (props.screen_orientation === "landscape") ? 
      " width_4 " : " width_6 ";
    }
    preview_buttons = preview_contents_list.map((contents) => {
      let para_num_ref;
      let preview_button_contents;
      let preview_button_text;
      if (contents.length === 3) {
        image = <Image_preview
        opened_article = {props.opened_article}
        image_orientation = {contents[1]["orientation"]}
        pic_info={contents[1]}
        />
        para_num_ref = 2;
      } else {
        image = <div/>;
        para_num_ref = (contents.length === 3) ? 2 : 1;
      }
      preview_button_text = props.section_info["section_title"] + "/" + contents[0];
      preview_button_contents = <div
        className={"shown_flex f-col-fs-c-c non_wrappable " +
        "height_auto width_12 margin_0 padding_9_left_right " +
        "transparent_object non-bordered"}
        >
          <Heading
          heading_text={contents[0]}
          class_list = {"width_12 padding_3_top_bottom " +
          "montserrat_600 color_3_font large_font"}
          />
          {image}
          <Standard_para
          size_color_other_classes={" height_auto width_12 " + 
          "color_3_font small_font overflow_ellipsis"}
          para_text={contents[para_num_ref]["contents"]}
          />
          <p
          className="shown_flex f-col-c-c-c wrappable 
          height_auto width_12 margin_0 padding_6_top_bottom
          transparent_object non-bordered 
          color_3_font montserrat_600 medium_font centered_font"
          >
            Expand
          </p>
        </div>
      return (
        <Link_button
        button_flex_class = " f-col-fs-c-c " 
        button_size_class = {" height_auto " + width_class}
        button_supplementary_class = " non-bordered "
        hover_type = "from_transparent"
        local_click_action = {local_open_sub_section}
        button_text={preview_button_text}
        article_name={contents[0]}
        button_contents = {preview_button_contents}
        key = {contents[0]}
        tab_index_ref = "0"
        focusable_on_load = {(preview_contents_list.indexOf(contents) === 0) ? 
        "true" : "false"}
        />
      );        
    });      
    return preview_buttons;
  }
  const holder_wrap_class = (props.layout === "mobile") ? 
  " f-col-fs-c-c non-wrappable " : " f-r-fs-fs-c wrappable ";
  return (
    <div
    className = {"shown_flex f-col-fs-c-c non-wrappable " +
    "height_auto width_12 margin_0 padding_0 non-bordered " +
    "color_1_object"}
    >
      {generate_section_heading()}
      <div
      className={"shown_flex "+ holder_wrap_class +
      "height_auto width_12 margin_0 padding_9_global non-bordered " +
      "transparent_object"}
      >
        {generate_preview_buttons()}
      </div>
    </div>
  );
}

export default Contents_preview;