import React from 'react';
import '../styles/App.css';
import Link_button from './Link_button';
import Normal_button from './Normal_button'
import {menu_button_text, home_button_text} from '../shared/supplementary_buttons_texts';

class Navigation_panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: "false"
    }
    this.affected_link = React.createRef();
    this.toggle_panel_open = this.toggle_panel_open.bind(this);
    this.close_panel = this.close_panel.bind(this);
    this.generate_home_button = this.generate_home_button.bind(this);
    this.generate_menu_button = this.generate_menu_button.bind(this);
    this.generate_login_button = this.generate_login_button.bind(this);
    this.login_link_action = this.login_link_action.bind(this);
    this.generate_nav_buttons_holder = 
    this.generate_nav_buttons_holder.bind(this);
    this.nav_buttons_click_action = this.nav_buttons_click_action.bind(this);
  }
  nav_buttons_click_action(button) {
    if (this.props.layout !== "mobile") {
    } else {
      this.close_panel();
    }
  }
  toggle_panel_open() {
    if (this.state.opened === "false") {  
      this.setState({
        opened: "true"
      })
    } else {
      this.setState({
        opened: "false"
      });
    }
  }
  close_panel() {
    this.setState({
      opened: "false",
    });
  }
  generate_home_button() {
    let home_button_converted_text = (this.props.layout === "mobile") ? 
    home_button_text : home_button_text.replace("h", "H");
    let home_button_size_class = (this.props.layout === 'mobile') ?
    " height_8 width_auto " : " height_8 width_auto ";
    let home_button_text_class = (this.props.layout === 'mobile') ?
    " material-icons large_icons_font centered_font " : 
    " montserrat_600 medium_font centered_font ";
    let home_button;    
    home_button = <Link_button
    button_flex_class = " f-col-c-c-c " 
    button_size_class = {home_button_size_class}
    button_text_class = {home_button_text_class}
    button_supplementary_class = " soft_corner_bottom_left_top_border "
    hover_type = "from_color_1"
    selected = {(this.props.selected_button === "Home") ? "true" : "false"}
    button_text = {home_button_converted_text}
    local_click_action={this.props.return_to_homepage}
    tab_index_ref="0"
    />
    return home_button;
  }
  generate_menu_button() {
    let menu_button;
    if (this.props.layout === "mobile") {
      menu_button = <Normal_button
      button_flex_class = " f-col-c-c-c " 
      button_size_class = {" height_8 width_auto "}
      button_text_class = " material-icons large_icons_font centered_font "
      button_supplementary_class = " soft_corner_bottom_left_top_border "
      hover_type = "from_color_1"
      button_text = {menu_button_text}
      selected = {this.state.opened}
      local_click_action={this.toggle_panel_open}
      tab_index_ref="0"
      pop_up = "true"
      />
    } else {
      menu_button = <div></div>
    }
    return menu_button;
  }
  generate_login_button() {
    let login_button_text = (this.props.layout === "mobile") ?
    "person" : "Login";
    let login_button_size_class = (this.props.layout === 'mobile') ?
    " height_8 width_auto " : " height_8 width_auto ";
    let login_button_text_class = (this.props.layout === 'mobile') ?
    " material-icons large_icons_font centered_font " : 
    " montserrat_600 medium_font centered_font ";
    let login_button_contents = 
    <a
    id="login_link"
    href="https://simplicitas.co.nz:8001/login/"
    className = "no_display"
    target="_blank"
    ref = {this.affected_link}
    >
      {login_button_text}
    </a>
    let login_button = <Normal_button
    button_size_class = {login_button_size_class}
    button_flex_class = " f-col-c-c-c "
    button_text_class = {login_button_text_class}
    button_supplementary_class = " soft_corner_bottom_left_top_border "
    hover_type = "from_color_1"
    button_text = {login_button_text}
    text_prevailing = "true"
    button_contents={login_button_contents}
    local_click_action={this.login_link_action}
    tab_index_ref = "0"
    />
    return login_button;
  }
  login_link_action() {
    this.affected_link.current.click();
  }
  generate_nav_buttons_holder() {
    let nav_buttons_holder;
    let nav_buttons_holder_temporary_classes;
    let nav_buttons_size_class;
    let nav_buttons_supplementary_class;
    if (this.props.layout === "mobile") {
      nav_buttons_size_class = " height_auto width_12 ";
      nav_buttons_supplementary_class = " non-bordered ";
    } else {
      nav_buttons_size_class = " height_8 width_auto ";
      nav_buttons_supplementary_class = " soft_corner_bottom_left_top_border ";
    }
    const nav_buttons = this.props.main_nav_buttons.map((button_text) => {
      return (
        <Link_button
        button_size_class = {nav_buttons_size_class}
        button_flex_class = " f-col-c-c-c "
        button_text_class = " montserrat_600 medium_font centered_font "
        button_supplementary_class = {nav_buttons_supplementary_class}
        hover_type = "from_color_1"
        button_text={button_text["section_title"]}
        key={((button_text["section_title"]).toLowerCase()).replace(/ /g, "_")}
        selected={(button_text["section_title"] === 
        this.props.selected_button) ? "true" : "false"}
        local_click_action={this.nav_buttons_click_action}
        tab_index_ref = "0"
        />
      );
    });
    if (this.props.layout === "mobile") {
      nav_buttons_holder_temporary_classes = "position_absolute " +
      "position_top_100 width_auto height_auto f-col-fs-c-c z_index_5 " + 
      "color_1_object"
    } else {
      nav_buttons_holder_temporary_classes = "position_relative " + 
      "transparent_object f-r-fe-fe-c width_auto height_12"
    }
    if (this.props.layout === "mobile" && this.state.opened === "false") {
      nav_buttons_holder = <div></div>
    } else {
      nav_buttons_holder = <div
      className={"shown_flex non-wrappable " +
      "margin_0 padding_0 non-bordered " +
      nav_buttons_holder_temporary_classes}
      >
        {nav_buttons}
      </div>
    }
    return nav_buttons_holder;
  }
  render () {
    return (
      <nav
      id="nav_panel"
      className="position_relative shown_flex f-r-fe-fe-c non-wrappable
      color_1_object height_12 width_auto opaque margin_0
      padding_0 non-bordered no_outline"
      >
        {this.generate_home_button()}
        {this.generate_login_button()}
        {this.generate_menu_button()}
        {this.generate_nav_buttons_holder()}        
      </nav>
    )
  };
}

export default Navigation_panel;