import React from 'react';
import '../styles/App.css';
import Normal_button from './Normal_button';
import Back_button from './Back_button';
import Heading from './Heading';
import Standard_para from './Standard_para';
import Para_with_links from './Para_with_links';
import Image_preview from './Image_preview';
import Carousel from './Carousel';

class Article_main_contents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened_image: ""
    }
    this.determine_article_contents = this.determine_article_contents.bind(this);
    this.article_open_image = this.article_open_image.bind(this);
    this.close_image = this.close_image.bind(this);
  }
  article_open_image(button) {
    this.props.open_image(button);
  }
  close_image() {
    this.props.close_image();
  }
  determine_article_contents() {
    let back_button;
    let article_contents = 
    this.props.opened_article["article_contents"].map((contents) => {
      if ((contents["type"] === "para") && (contents["visibility"] !== "hidden")) {
        let para_to_print;
        if (contents["sub_type"] === "standard_para_with_intends") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 padding_6_global " + 
          " transparent_object color_3_font small_font text_with_intendation "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        } else if (contents["sub_type"] === "standard_para_without_intends") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 padding_6_global " + 
          " transparent_object color_3_font small_font "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        } else if (contents["sub_type"] === "list_level_one") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 " + 
          " transparent_object color_3_font small_font padding_20_left "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        } 
         else if (contents["sub_type"] === "list_sub_heading") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 padding_6_global " + 
          " transparent_object color_3_font medium_font padding_20_left_10_bottom "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        } else if (contents["sub_type"] === "sub_heading") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 padding_10_bottom " + 
          " transparent_object color_3_font medium_font "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        } else if (contents["sub_type"] === "sub_heading_followed_by_para") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 padding_10_6_3_6 " + 
          " transparent_object color_3_font medium_font "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        } else if (contents["sub_type"] === "para_sub_heading") {
          para_to_print = <Standard_para
          size_color_other_classes={"height_auto width_12 padding_6_global " + 
          " transparent_object color_3_font medium_font "}
          para_text={contents["contents"]}
          key={"para_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
          tabbable = "true"
          />
        }
        return para_to_print;
      } else if ((contents["type"] === "image")&&(contents["visibility"] !== "hidden")) {
        return <Image_preview
        opened_article = {this.props.opened_article}
        screen_orientation = {this.props.screen_orientation}
        image_orientation = {contents["orientation"]}
        contents_width = " width_12 "
        pic_info = {contents}
        carousel_image = "false"
        key={"para_with_links_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
        />
      } else if (contents["type"] === "carousel") {
        return <Carousel
        opened_article = {this.props.opened_article}
        screen_orientation = {this.props.screen_orientation}
        contents_width = " width_12 "
        carousel_info = {contents}
        key={"carousel_" + 
        this.props.opened_article["article_contents"].indexOf(contents)}
        local_click_action={this.article_open_image}
        />;
      } else if (contents["type"] === "para_with_links") {
        return <Para_with_links
          contents = {contents["details"]}
          holder_size_color_classes = {" height_auto width_12 transparent_object "}
          font_classes = " color_3_font small_font padding_0 "
          key={"para_with_links_" + 
          this.props.opened_article["article_contents"].indexOf(contents)}
        />
      };
    });
    if ((this.props.no_close !== "true") && 
    (this.props.section_info.section_contents.length > 1)) {
      back_button = <Back_button
      button_flex_class = " f-col-c-c-c " 
      button_size_class = {" height_auto width_6 "}
      button_text_class = "montserrat_600 medium_font centered_font "
      button_supplementary_class = " non-bordered "
      hover_type = "from_color_1"
      local_click_action = {this.props.close_sub_section}
      button_text={this.props.section_info.section_title}
      tab_index_ref = "0"
      />
    } else {
    back_button = <div></div>
    }
    return <article
    id = "article"
    >
      <Heading
      heading_text = {this.props.opened_article["article_name"]}
      class_list = {"width_12 padding_9_top_bottom " + 
      "montserrat_600 color_3_font large_font"}
      tabbable = "true"
      initial_focus = "true"
      />
      <div
      className={"shown_flex f-col-fs-c-c non-wrappable " +
      "height_auto width_12 margin_0 padding_0 non-bordered " +
      "transparent_object "}
      >
        {article_contents}
      </div>
      {back_button}
    </article>
  }
  render() {
    let article_contents = this.determine_article_contents();
    return article_contents;
  }
}
export default Article_main_contents;